import React from 'react';
import ReactMarkdown from 'react-markdown';
import '../App.css';

const MessageList = ({ messages, loading }) => {
  return (
    <div className='fixed-style'>
      <h3 className='fw-bolder text-secondary-emphasis text-center mb-4'>Rayhudsons AI Assistant</h3>
      {loading && <p className='text-success'>Loading...</p>}
      {messages.length === 0 ? (
        <p>No messages yet. Try sending a question or inquiries</p>
      ) : (
        messages.map((message, index) => (
          <div key={index} className="d-flex flex-column align-items-start mb-3">
            <div className={`p-2 rounded ${message.is_from_user ? 'bg-primary text-white' : 'bg-secondary text-white'}`}>
              <strong>{message.is_from_user ? 'You' : 'Chatbot Assistant'}:</strong>{' '}
              {message.is_from_user ? (
                <span>{message.prompt}</span>
              ) : (
                <ReactMarkdown>{message.response || 'Loading... please wait'}</ReactMarkdown>
              )}
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default MessageList;