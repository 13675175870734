import ChatThread from "./components/ChatThread";
import './App.css';

function App() {

  return (
    <div>
      {/* Navbar */}
      <nav className="navbar ps-5 pe-5 navbar-expand-lg bg-dark navbar-dark">
        <div className="container-fluid">
          <a href='https://rayhudsonsgroup.org/' className="navbar-brand text-light fw-bolder">Rayhusons Group</a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
              <a href='https://rayhudsonsgroup.org/' className="nav-link text-light me-4 fw-bolder"> Return to Home</a>
              </li>
      
            </ul>
          </div>
        </div>
      </nav>
      <ChatThread />
      <footer className="text-center bg-dark p-5 text-white fw-bolder margin-t">2024 &copy; All Rights Reserved - Powered By Rayhudsons Group</footer>
    </div>
  );
}

export default App;
 