import React, { useState } from 'react';
import axios from 'axios';
import MessageList from './MessageList';
import MessageInput from './MessageInput';
import '../App.css';

const ChatThread = () => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSendMessage = async (message) => {
    setLoading(true);

    try {
      let threadId;
      if (messages.length === 0) {
        const threadResponse = await axios.post('https://api.rayhudsonsgroup.org/api/chat-threads/');
        threadId = threadResponse.data.id;
      } else {
        threadId = messages[0].id;
      }

      const messageResponse = await axios.post('https://api.rayhudsonsgroup.org/api/messages/', {
        thread_id: threadId,
        prompt: message,
      });

      const newMessage = {
        id: messageResponse.data.id,
        prompt: message,
        response: messageResponse.data.response,
        is_from_user: false,
      };

      setMessages((prevMessages) => [...prevMessages, { id: prevMessages.length + 1, prompt: message, response: '', is_from_user: true }, newMessage]);
    } catch (error) {
      alert('Error sending message');
      console.error('Error sending message:', error.response?.data);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='chat'>
      <div className='mb-4'>
        <MessageList messages={messages} loading={loading} />
      </div>
      <MessageInput className='sticky-sm-bottom' onSendMessage={handleSendMessage} disabled={loading} />
    </div>
  );
};

export default ChatThread;