import React, { useState } from 'react';

const MessageInput = ({ onSendMessage, disabled }) => {
  const [message, setMessage] = useState('');

  const handleInputChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (message.trim() !== '') {
      onSendMessage(message);
      setMessage('');
    }
  };

  return (
    <form onSubmit={handleSubmit} className=" align-items-center mb-4">
      <textarea
        className="form-control me-2 input-field"
        rows="2"
        value={message}
        onChange={handleInputChange}
        placeholder="Type your question or inquiry..."
        disabled={disabled}
      />
      <button className="btn btn-primary" type="submit" disabled={disabled}>
        Send <i className=" fas fa-paper-plane"></i>
      </button>
    </form>
  );
};

export default MessageInput;